<template>
  <div class="insight_detail" v-loading="totalLoading">
    <el-card style="margin-bottom: 21px; border-radius: 10px">
      <div class="insight_title" style="display: flex">
        <div style="flex: 1; display: flex">
          <div
            style="margin-right: 22px; color: #717171; font-size: 16px"
            v-show="overView.groupName"
          >
            <svg-icon
              class-name="dashboard_tag"
              style="margin-right: 8px; width: 20px"
            />
            {{ overView.groupName }}
          </div>
          <div
            style="margin-right: 22px; color: #717171; font-size: 16px"
            v-show="overView.brandName"
          >
            <svg-icon
              class-name="dashboard_tag"
              style="margin-right: 8px; width: 20px"
            />
            {{ overView.brandName }}
          </div>
          <div
            style="color: #717171; font-size: 16px; flex: 1"
            v-show="overView.productName"
          >
            <svg-icon
              class-name="dashboard_tag"
              style="margin-right: 8px; width: 20px"
            />
            <el-tooltip placement="top" :enterable="false">
              <template #content>
                <div class="popper_class">
                  {{ overView.productName }}
                </div>
              </template>
              <span class="ellipsis"> {{ overView.productName }} </span>
            </el-tooltip>
          </div>
        </div>
        <span style="color: #4f4f4f"
          >{{ overView.startDate }} ~ {{ overView.endDate }}</span
        >
      </div>
      <div style="display: flex">
        <div style="flex: 1; margin-right: 20px">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 20px;
            "
          >
            <div class="item_square_content">
              <div class="space_between_item">
                <svg-icon class-name="dashboard_cost" class="icon_svg" />
                <count-to
                  class="big_text"
                  :endVal="overView.cost / 100"
                  :duration="1000"
                />
              </div>
              <div>
                <svg-icon class-name="black_line" class="line_svg" />
                <div class="space_between_item">
                  <span class="normal_text">总费用</span>
                  <span class="normal_text gray_text">元</span>
                </div>
              </div>
            </div>
            <div class="item_square_content">
              <div class="space_between_item">
                <svg-icon class-name="dashboard_kols" class="icon_svg" />
                <count-to
                  class="big_text"
                  :endVal="overView.kolNum * 1"
                  :duration="1000"
                />
              </div>
              <div>
                <svg-icon
                  class-name="black_line"
                  class="line_svg"
                  style="margin-top: 0"
                />
                <div class="space_between_item">
                  <span class="normal_text">KOL数</span>
                  <span class="normal_text gray_text">个</span>
                </div>
              </div>
            </div>
            <div class="item_square_content">
              <div class="space_between_item">
                <svg-icon class-name="dashboard_posts" class="icon_svg" />
                <count-to
                  class="big_text"
                  :endVal="overView.postNum * 1"
                  :duration="1000"
                />
              </div>
              <div>
                <svg-icon class-name="black_line" class="line_svg" />
                <div class="space_between_item">
                  <span class="normal_text">推文数</span>
                  <span class="normal_text gray_text">条</span>
                </div>
              </div>
            </div>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div
              class="item_color_content"
              :style="{
                background: `url(${require('./imgs/red_bg.png')}) 0 0 / cover`,
              }"
            >
              <svg-icon class-name="dashboard_impression" class="icon_svg" />
              <div>
                <svg-icon class-name="white_line" class="line_svg" />
                <count-to
                  v-if="overView.exposure"
                  class="big_text white_text"
                  :endVal="overView.exposure * 1"
                  :duration="1000"
                />
                <p v-else class="white_text big_text">N/A</p>
                <p class="white_text normal_text">总曝光</p>
              </div>
            </div>
            <div
              class="item_color_content"
              :style="{
                background: `url(${require('./imgs/orange_bg.png')}) 0 0 / cover`,
              }"
            >
              <svg-icon class-name="dashboard_engagement" class="icon_svg" />
              <div>
                <svg-icon class-name="white_line" class="line_svg" />
                <count-to
                  v-if="overView.engagement"
                  class="big_text white_text"
                  :endVal="overView.engagement * 1"
                  :duration="1000"
                />
                <p v-else class="white_text big_text">N/A</p>
                <p class="white_text normal_text">总互动</p>
              </div>
            </div>
            <div
              class="item_color_content"
              :style="{
                background: `url(${require('./imgs/blue_bg.png')}) 0 0 / cover`,
              }"
            >
              <svg-icon class-name="dashboard_cpi" class="icon_svg" />
              <div>
                <svg-icon class-name="white_line" class="line_svg" />
                <count-to
                  v-if="overView.cpi"
                  class="big_text white_text"
                  :endVal="overView.cpi"
                  :duration="1000"
                  :decimals="2"
                />
                <p v-else class="white_text big_text">N/A</p>
                <p class="white_text normal_text">CPI</p>
              </div>
            </div>
            <div
              class="item_color_content"
              :style="{
                background: `url(${require('./imgs/green_bg.png')}) 0 0 / cover`,
              }"
            >
              <svg-icon class-name="dashboard_cpe" class="icon_svg" />
              <div>
                <svg-icon class-name="white_line" class="line_svg" />
                <count-to
                  v-if="overView.cpe"
                  class="big_text white_text"
                  :endVal="overView.cpe"
                  :duration="1000"
                  :decimals="2"
                />
                <p v-else class="white_text big_text">N/A</p>
                <p class="white_text normal_text">CPE</p>
              </div>
            </div>
            <!-- <div
              class="item_color_content"
              :style="{
                background: `url(${require('./imgs/purple_bg.png')}) 0 0 / cover`,
              }"
            >
              <svg-icon class-name="dashboard_cps" class="icon_svg" />
              <div>
                <svg-icon class-name="white_line" class="line_svg" />
                <count-to
                  v-if="overView.searchRate"
                  class="big_text white_text"
                  :endVal="overView.searchRate"
                  :duration="1000"
                  :decimals="2"
                />
                <p v-else class="white_text big_text">N/A</p>
                <p class="white_text normal_text">电商搜索指数</p>
              </div>
            </div> -->
          </div>
        </div>
        <div
          style="
            width: 260px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
          "
        >
          <div class="item_annular_content">
            <one-data-annular
              ref="engagementRate"
              :data="overView.engagementRate"
              class="annular_content"
            />
            <div class="tooltip_content">
              <p class="tooltip_number">
                {{
                  overView.engagementRate
                    ? overView.engagementRate + "%"
                    : "N/A&nbsp;"
                }}
              </p>
              <div style="display: flex; align-items: center">
                <span class="tooltip_text">ER</span>
                <el-tooltip
                  effect="dark"
                  content="互动数 / 曝光数"
                  placement="top"
                >
                  <svg-icon
                    class-name="dashboard_tooltip"
                    class="tooltip_icon"
                  ></svg-icon>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <el-card
      :body-style="{ display: 'flex', flexDirection: 'column' }"
      style="margin-bottom: 21px; border-radius: 10px"
      v-loading="tableLoading"
    >
      <p class="insight_title">品牌声量分布</p>
      <div
        style="
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-bottom: 10px;
        "
      >
        <el-radio-group
          v-model="type"
          style="margin-right: 60px"
          @change="getDistribution"
        >
          <el-radio label="percent">占比</el-radio>
          <el-radio label="amount">数量</el-radio>
        </el-radio-group>
        <el-button size="mini" @click="handleExpansion">
          {{ expansion.includes(false) ? "全部展开" : "全部收起" }}
          <i
            :class="
              expansion.includes(false)
                ? 'el-icon-arrow-down'
                : 'el-icon-arrow-up'
            "
          ></i>
        </el-button>
      </div>
      <el-table
        :data="tableData"
        ref="treeTable"
        stripe
        row-key="tier"
        :tree-props="{ children: 'details' }"
        @expand-change="expandChange"
        :header-cell-style="{ color: '#8c98a5' }"
        :cell-style="{ color: '#34355B' }"
      >
        <el-table-column
          v-for="item in colData"
          :key="item.prop"
          :label="item.label"
          :prop="item.prop"
          :align="item.align"
          :width="item.width"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :formatter="item.formatter"
          :label-class-name="item.labelClassName"
          :class-name="item.className"
        >
        </el-table-column>
      </el-table>
    </el-card>
    <el-card
      :body-style="{
        height: '460px',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 0,
      }"
      style="margin-bottom: 21px; border-radius: 10px"
      v-loading="chartLoading"
    >
      <p class="insight_title">投放节奏</p>
      <div style="display: flex; justify-content: space-between">
        <div style="flex: 1; display: flex; align-items: flex-start">
          <el-select
            v-model="trendPlatform"
            multiple
            placeholder="请选择"
            size="mini"
            collapse-tags
            @change="handleTrendPlatform"
          >
            <el-option
              v-for="item in trendOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div
            style="
              flex: 1;
              display: flex;
              flex-wrap: wrap;
              margin-left: 20px;
              margin-top: 2px;
            "
          >
            <legend-com
              v-for="legend in echartLegend"
              :key="legend.name"
              :data="legend"
            />
          </div>
        </div>
        <div>
          <el-radio-group
            v-model="echartsType"
            style="margin-right: 40px"
            @change="getTrend"
          >
            <el-radio label="cost">费用</el-radio>
            <el-radio label="exposure">曝光</el-radio>
            <el-radio label="engagement">互动</el-radio>
          </el-radio-group>
          <el-radio-group v-model="echartsTime" size="mini" @change="getTrend">
            <el-radio-button label="day">天</el-radio-button>
            <el-radio-button label="week">周</el-radio-button>
            <el-radio-button label="month">月</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <line-bar
        :xAxis="echartxAxis"
        :series="echartSeries"
        :color="echartColor"
      />
    </el-card>
    <el-card style="margin-bottom: 21px; border-radius: 10px">
      <h2 class="insight_title">人群画像</h2>
      <div
        style="display: flex; justify-content: space-between; flex-wrap: wrap"
      >
        <stagger-x-bar
          style="width: 48%; height: 260px; margin-bottom: 50px"
          v-for="(item, i) in profileData"
          :ref="'staggerXBar' + i"
          :key="item.title"
          :title="item.title"
          :xAxisData="item.xAxisData"
          :yAxisData="item.yAxisData"
          :seriesData="item.seriesData"
          :color="
            item.title === '性别占比'
              ? ['rgb(83, 186, 210)', '#e893a8']
              : item.title === '城市级别占比'
              ? ['#32a3be', 'rgb(83, 186, 210)', '#e893a8', '#e37893']
              : [
                  '#2c7d98',
                  '#32a3be',
                  'rgb(83, 186, 210)',
                  '#d1eef8',
                  '#f0a3b6',
                  '#e37893',
                  '#a02947',
                ]
          "
        />
      </div>
    </el-card>
    <el-card style="margin-bottom: 21px; border-radius: 10px">
      <h2 class="insight_title">内容分析</h2>
      <div class="echarts_word_cloud_container">
        <div style="width: 45%">
          <p class="echarts_word_cloud_title">
            品类热词
            <el-tag
              style="margin-left: 10px"
              @close="tagClose(tag)"
              v-for="tag in tags"
              :key="tag"
              closable
            >
              {{ tag }}
            </el-tag>
          </p>
          <echarts-word-cloud
            class="echarts_word_cloud_content"
            :words="categoryHotwords"
            @clickHotWords="clickHotWord"
          />
        </div>
        <div style="width: 45%">
          <p class="echarts_word_cloud_title">用户评论热词</p>
          <echarts-word-cloud
            class="echarts_word_cloud_content"
            :words="commentsHotwords"
            :isProuctHotWord="false"
          />
        </div>
      </div>
    </el-card>
    <el-card style="border-radius: 10px">
      <h2 class="insight_title">KOL投放内容</h2>
      <el-button
        type="primary"
        size="mini"
        style="float: right; margin-bottom: 10px"
        @click="downloadArticle"
        :loading="btnLoading"
      >
        下载<i
          class="el-icon-download"
          style="
            margin-left: 8px;
            font-weight: bold;
            font-size: 18px;
            vertical-align: text-bottom;
          "
        ></i>
      </el-button>
      <el-table
        :data="top100TableData"
        :cell-style="{ color: '#34355b' }"
        :header-cell-style="{ backgroundColor: '#F6F8FA', color: '#636A72' }"
        v-loading="articleTopLoading"
      >
        <el-table-column
          v-for="item in top100ColData"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          align="center"
          :formatter="item.formatter"
        >
          <template #header="scope">
            <el-select
              v-if="item.prop === 'platform'"
              v-model="top100Platform"
              placeholder="平台"
              size="mini"
              @change="handleTop100Platform"
            >
              <el-option label="全平台" value="all" />
              <el-option
                v-for="item in top100Options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <div v-else>{{ scope.column.label }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: right; margin-top: 20px">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          :total="totalSize"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import { h } from 'vue';
import oneDataAnnular from "@/components/echarts/oneDataAnnular";
import legendCom from "../Dashboard/legendCom";
import lineBar from "../Dashboard/lineBar";
import staggerXBar from "@/components/echarts/staggerXBar";
import echartsWordCloud from "@/components/echarts/echarts_wordCloud";
import {
  getCampaignInsightOverview,
  getCampaignInsightDistribution,
  getCampaignInsightTrend,
  getCampaignInsightProfile,
  getCampaignInsightContent,
  getCampaignInsightArticleTop,
  downloadArticle,
} from "@/api/campaignInsight";
import { mapGetters } from "vuex";

export default {
  name: "insightDetail",
  data() {
    return {
      overView: {
        campaignName: "",
        groupName: "",
        brandName: "",
        productName: "",
        startDate: "",
        endDate: "",
        cost: "",
        kolNum: "",
        searchNum: "",
        postNum: "",
        exposure: "",
        engagement: "",
        cpi: "",
        cpe: "",
        cps: "",
        engagementRate: "",
        searchRate: "",
      },
      expansion: [],
      type: "percent",
      // 手动加入rank字段，将平台改名为tier，details里的tier加工
      tableData: [],
      colData: [
        {
          label: "平台",
          prop: "tier",
          sortable: false,
          width: null,
          align: null,
          fixed: false,
          formatter: (row, column, cellValue) => {
            const cellValueStr = cellValue + "";
            if (cellValueStr.includes("KOC")) {
              return "KOC";
            } else if (cellValueStr.includes("Tier")) {
              return cellValueStr.slice(0, 5);
            } else {
              const platformIcon = `dashboard_platform_${cellValueStr}`;
              const platform = h('div', {
                style: 'display: inline-flex; align-items: center;'
              }, [
                h('svg-icon', {
                  class: platformIcon,
                  style: 'margin-right: 10px; font-size: 20px; color: red;'
                }),
                h('span', {}, this.platformToZn(cellValueStr))
              ]);

              return platform;
              // const platformIcon = `dashboard_platform_${cellValueStr}`;
              // const platform = (
              //   <div style="display: inline-flex;align-items: center;">
              //     <svg-icon
              //       className={platformIcon}
              //       style="margin-right: 10px;font-size: 20px;color: red;"
              //     />
              //     <span>{this.platformToZn(cellValueStr)}</span>
              //   </div>
              // );
              // return platform;
            }
          },
        },
        {
          label: "费用",
          prop: "cost",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue
              ? this.type === "percent"
                ? cellValue
                : this.sliceDecimal(cellValue / 100)
              : "--";
          },
        },
        {
          label: "KOL",
          prop: "kolNum",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue || "--";
          },
        },
        {
          label: "推文",
          prop: "postNum",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue || "--";
          },
        },
        {
          label: "曝光",
          prop: "exposure",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue
              ? this.type === "percent"
                ? cellValue
                : this.sliceDecimal(cellValue)
              : "--";
          },
        },
        {
          label: "互动",
          prop: "engagement",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue
              ? this.type === "percent"
                ? cellValue
                : this.sliceDecimal(cellValue)
              : "--";
          },
        },
        {
          label: "ER",
          prop: "engagementRate",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue || "--";
          },
          labelClassName: "dash_board_table_special_col_header",
          className: "dash_board_table_special_col",
        },
        {
          label: "CPI",
          prop: "cpi",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue || "--";
          },
          className: "dash_board_table_special_col",
        },
        {
          label: "CPE",
          prop: "cpe",
          sortable: false,
          width: null,
          align: "center",
          fixed: false,
          formatter: (row, column, cellValue) => {
            return cellValue || "--";
          },
          className: "dash_board_table_special_col",
        },
      ],
      trendPlatform: [],
      trendOptions: [],
      echartsType: "cost",
      echartsTime: "day",
      echartLegend: [
        { name: "cam1", color: "#7CA526" },
        { name: "cam2", color: "#7CA526" },
        { name: "cam3", color: "#7CA526" },
        { name: "cam4", color: "#7CA526" },
        { name: "cam5", color: "#7CA526" },
        { name: "cam6", color: "#7CA526" },
        { name: "cam7", color: "#7CA526" },
        { name: "cam8", color: "#7CA526" },
      ],
      echartxAxis: {
        data: [
          201905, 201906, 201907, 201908, 201909, 201910, 201911, 201912,
          202001, 202002,
        ],
        type: "category",
      },
      echartSeries: [
        {
          data: [
            73079874, 73584250, 55022549, 62489669, 54538919, 42380599,
            28357890, 42266074, 53824757, 38509705,
          ],
          name: "cam1",
          type: "line",
          smooth: true,
        },
      ],
      echartColor: ["rgb(83, 186, 210)", "#e37893", "#daae3c"],
      defaultColors: [
        "#2c7d98",
        "#d1eef8",
        "#f0a3b6",
        "#a02947",
        "#06c1ff",
        "#65aeb1",
        "#bda29a",
        "#ff7c7c",
        "#ffd3d3",
        "#db7cae",
        "#d3baed",
        "#966767",
        "#4fe3ff",
        "#ffa86d",
        "#6797fe",
        "#6c80fe",
        "#ffb57e",
        "#4ebcb4",
      ],
      profileData: [],
      categoryHotwords: [],
      commentsHotwords: [],
      top100TableData: [],
      top100ColData: [
        {
          label: "品牌",
          prop: "brandName",
          width: 100,
        },
        {
          label: "产品",
          prop: "productName",
          width: null,
        },
        {
          label: "KOL名称",
          prop: "kolName",
          width: 180,
        },
        {
          label: "平台",
          prop: "platform",
          width: 120,
          formatter: (row, column, cellValue) => {
            return this.platformToZn(cellValue);
          },
        },
        {
          label: "粉丝数",
          prop: "fansNum",
          width: 140,
          formatter: (row, column, cellValue) => {
            return this.sliceDecimal(cellValue);
          },
        },
        {
          label: "发文日期",
          prop: "postDate",
          width: 130,
        },
        {
          label: "文章链接",
          prop: "link",
          width: 200,
          formatter: (row, col, cellValue) => {
            return h('el-tooltip', {
              placement: 'top',
              enterable: false,
              content: cellValue,
              'popper-class': 'popper_class'
            }, [
              h('a', {
                class: 'ellipsis',
                href: cellValue,
                target: '_blank',
                style: 'text-decoration: underline; color: #409eff;'
              }, cellValue)
            ]);
            // return (
            //   <el-tooltip
            //     placement="top"
            //     enterable={false}
            //     content={cellValue}
            //     popper-class="popper_class"
            //   >
            //     <a
            //       class="ellipsis"
            //       href={cellValue}
            //       target="_blank"
            //       style="text-decoration: underline;color: #409eff;"
            //     >
            //       {cellValue}
            //     </a>
            //   </el-tooltip>
            // );
          },
        },
        {
          label: "阅读量",
          prop: "exposure",
          width: 110,
          formatter: (row, column, cellValue) => {
            return this.sliceDecimal(cellValue);
          },
        },
        {
          label: "互动数",
          prop: "engagement",
          width: 110,
          formatter: (row, column, cellValue) => {
            return this.sliceDecimal(cellValue);
          },
        },
      ],
      top100Options: [
        {
          value: "wechat",
          label: "微信",
        },
        {
          value: "weibo",
          label: "微博",
        },
        {
          value: "douyin",
          label: "抖音",
        },
        {
          value: "xiaohongshu",
          label: "小红书",
        },
        {
          value: "bilibili",
          label: "哔哩哔哩",
        },
      ],
      top100Platform: "all",
      currentPage: 1,
      pageSize: 10,
      totalSize: 100,
      hotWord: "",
      totalLoading: true,
      tableLoading: false,
      chartLoading: false,
      tags: [],
      btnLoading: false,
      articleTopLoading: true,
    };
  },
  computed: {
    ...mapGetters(["month"]),
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.getOverview();
    this.getDistribution();
    this.initTrend();
    this.getProfile();
    this.getContent();
    this.getArticleTop();
    this.totalLoading = false;
  },
  methods: {
    async getOverview() {
      const { data } = await getCampaignInsightOverview({ id: this.id });
      this.overView = data;
    },
    // 声量分布
    async getDistribution() {
      this.tableLoading = true;
      const params = {
        id: this.id,
        type: this.type,
        mon: this.month,
      };
      const { data } = await getCampaignInsightDistribution(params);
      data.forEach((item, i) => {
        item.tier = item.platform;
        item.index = i;
        // tier的唯一性
        if (item.details && item.details.length) {
          // 将koc放到最后
          if (item.details[0].tier === 0) {
            item.details.push(item.details.splice(0, 1)[0]);
          }
          item.details.forEach((tier) => {
            tier.tier =
              (tier.tier === 0 ? "KOC" : "Tier" + tier.tier) +
              Math.random().toFixed(3);
          });
        } else {
          delete item.details;
        }
        this.expansion[i] = item.details && item.details.length ? false : null;
      });
      this.tableData = data;
      // 全部收起
      this.tableData.forEach((item) => {
        this.$refs.treeTable.toggleRowExpansion(item, false);
      });
      this.tableLoading = false;
    },
    expandChange(row, expanded) {
      this.expansion[row.index] = expanded;
    },
    handleExpansion() {
      const expanded = this.expansion.includes(false);
      this.tableData.forEach((item) => {
        this.$refs.treeTable.toggleRowExpansion(item, expanded);
      });
    },
    // 投放节奏
    async initTrend() {
      const params = {
        id: this.id,
        xType: this.echartsTime,
        yType: this.echartsType,
      };
      let { data } = await getCampaignInsightTrend(params);
      data = data.filter((item) => item.trend.length);
      this.originData = [].concat(data);
      this.filterOriginData = [].concat(data);
      this.initTrendData(data);
      data.forEach((campaign) => {
        this.trendOptions.push({
          label: this.platformToZn(campaign.name),
          value: campaign.name,
        });
        this.trendPlatform.push(campaign.name);
      });
    },
    async getTrend() {
      this.chartLoading = true;
      const params = {
        id: this.id,
        xType: this.echartsTime,
        yType: this.echartsType,
      };
      let { data } = await getCampaignInsightTrend(params);
      data = data.filter((item) => item.trend.length);
      this.originData = [].concat(data);
      this.filterOriginData = [].concat(data);
      this.handleTrendPlatform();
    },
    initTrendData(data) {
      this.echartLegend = [];
      const startTimeArr = [];
      const endTimeArr = [];
      data.forEach((campaign, i) => {
        this.echartLegend.push({
          name: this.platformToZn(campaign.name),
          color: this.echartColor.concat(this.defaultColors)[i],
        });
        startTimeArr.push(campaign.trend[0].xAxis);
        const lastIndex = campaign.trend.length - 1;
        endTimeArr.push(campaign.trend[lastIndex].xAxis);
      });
      if (this.echartsTime === "day") {
        // day
        this.formatDayDate(startTimeArr, endTimeArr);
      } else if (this.echartsTime === "week") {
        // week
        this.formatWeekDate(startTimeArr, endTimeArr);
      } else {
        // month
        this.formatMonthDate(startTimeArr, endTimeArr);
      }
      this.chartLoading = false;
    },
    formatDayDate(startTimeArr, endTimeArr) {
      const startTimeStamp = startTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = endTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getDayAllDate(startTime, endTime);
    },
    getDayAllDate(start, end) {
      let dateArr = [];
      let startArr = start.split("-");
      let endArr = end.split("-");
      let db = new Date();
      db.setFullYear(startArr[0], startArr[1] - 1, startArr[2]);
      let de = new Date();
      de.setFullYear(endArr[0], endArr[1] - 1, endArr[2]);
      let stampDb = db.getTime();
      let stampDe = de.getTime();
      const oneDay = 24 * 60 * 60 * 1000;
      for (stampDb; stampDb <= stampDe; stampDb += oneDay) {
        dateArr.push(this.timeStampToDate(stampDb));
      }
      this.echartxAxis.data = dateArr;
      this.getDataFromOrigin();
    },
    formatWeekDate(startTimeArr, endTimeArr) {
      const nStartTimeArr = startTimeArr.map((time) => time.slice(0, 10));
      const nEndTimeArr = endTimeArr.map((time) => time.slice(-10));
      const startTimeStamp = nStartTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = nEndTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getWeekAllDate(startTime, endTime);
    },
    getWeekAllDate(start, end) {
      let startArr = start.split("-");
      let endArr = end.split("-");
      let db = new Date();
      db.setFullYear(startArr[0], startArr[1] - 1, startArr[2]);
      let de = new Date();
      de.setFullYear(endArr[0], endArr[1] - 1, endArr[2]);
      let stampDb = db.getTime();
      let stampDe = de.getTime();
      const oneWeek = 7 * 24 * 60 * 60 * 1000;
      let stampArr = [];
      for (stampDb; stampDb <= stampDe; stampDb += oneWeek) {
        stampArr.push(stampDb);
      }
      const sixDay = 6 * 24 * 60 * 60 * 1000;
      let dateArr = [];
      dateArr = stampArr.map((stamp) => {
        const weekLastDayStamp = stamp + sixDay;
        return (
          this.timeStampToDate(stamp) +
          " ~ " +
          this.timeStampToDate(weekLastDayStamp)
        );
      });
      this.echartxAxis.data = dateArr;
      this.getDataFromOrigin();
    },
    formatMonthDate(startTimeArr, endTimeArr) {
      const startTimeStamp = startTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = endTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getMonthAllDate(startTime, endTime);
    },
    getMonthAllDate(start, end) {
      let startArr = start.split("-");
      let startYear = startArr[0] * 1;
      let startMonth = startArr[1] * 1;
      let endArr = end.split("-");
      let endYear = endArr[0] * 1;
      let endMonth = endArr[1] * 1;
      let dateArr = [];
      if (startYear < endYear) {
        for (startMonth; startMonth <= 12; startMonth++) {
          const tempStr =
            startYear + "-" + (startMonth < 10 ? "0" + startMonth : startMonth);
          dateArr.push(tempStr);
          if (startMonth === 12 && startYear + 1 < endYear) {
            startYear++;
            startMonth = 0;
          }
        }
        for (let i = 1; i <= endMonth; i++) {
          const tempStr = endYear + "-" + (i < 10 ? "0" + i : i);
          dateArr.push(tempStr);
        }
      } else {
        for (startMonth; startMonth <= endMonth; startMonth++) {
          const tempStr =
            endYear + "-" + (startMonth < 10 ? "0" + startMonth : startMonth);
          dateArr.push(tempStr);
        }
      }
      this.echartxAxis.data = dateArr;
      this.getDataFromOrigin();
    },
    timeStampToDate(timestamp) {
      const date = new Date(timestamp);
      const Y = date.getFullYear();
      const M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return Y + "-" + M + "-" + D;
    },
    getDataFromOrigin() {
      this.echartSeries = this.filterOriginData.map((campaign) => {
        const seriesItem = {
          data: [],
          name: this.platformToZn(campaign.name),
          type: "line",
          smooth: true,
        };
        // 重写逻辑：从整个时间轴上截取时间段，段内补0，段外补null
        const startTimeIndex = this.echartxAxis.data.indexOf(
          campaign.trend[0].xAxis
        );
        const endTimeIndex = this.echartxAxis.data.indexOf(
          campaign.trend[campaign.trend.length - 1].xAxis
        );
        const campaignxAxis = this.echartxAxis.data.slice(
          startTimeIndex,
          endTimeIndex + 1
        );
        seriesItem.data = this.echartxAxis.data.map((xAxis) => {
          let data = null;
          if (campaignxAxis.includes(xAxis)) {
            const trend = campaign.trend.find((item) => item.xAxis === xAxis);
            trend
              ? this.echartsType === "cost"
                ? (data = trend.yAxis / 100)
                : (data = trend.yAxis)
              : (data = 0);
          }
          return data;
        });
        return seriesItem;
      });
    },
    handleTrendPlatform() {
      this.filterOriginData = this.trendPlatform.map((filter) => {
        const tempArr = this.originData.filter(
          (campaign) => campaign.name === filter
        );
        return tempArr[0];
      });
      this.initTrendData(this.filterOriginData);
    },
    // 人群画像
    async getProfile() {
      const { data } = await getCampaignInsightProfile({ id: this.id });
      const yAxisData = [
        "wechat",
        "weibo",
        "xiaohongshu",
        "douyin",
        "bilibili",
      ];
      this.profileData = data.map((item) => {
        const arr = [];
        const temp = {
          yAxisData,
        };
        switch (item.name) {
          case "age":
            temp.title = "年龄占比";
            break;
          case "gender":
            temp.title = "性别占比";
            break;
          case "income":
            temp.title = "收入水平占比";
            break;
          case "cityTier":
            temp.title = "城市级别占比";
            break;
        }
        temp.xAxisData = item.profile.map((temp) => temp.label);
        const keyArr = item.profile.map((temp) => temp.id);
        yAxisData.forEach((platform) => {
          const temp = [];
          keyArr.forEach((key) => {
            temp.push(
              item.proportionObj[platform] && item.proportionObj[platform][key]
                ? Math.floor(item.proportionObj[platform][key] * 100)
                : null
            );
          });
          const index = temp.findIndex((item) => item != null);
          if (index !== -1) {
            // 更改temp的最后一个值以保证temp每项相加和为100
            let endValue = 100;
            temp.slice(0, -1).forEach((item) => {
              endValue = endValue - item;
            });
            temp[temp.length - 1] = endValue <= 0 ? 0 : endValue;
          }
          arr.push(temp);
        });
        // 转置
        temp.seriesData = arr[0].map((col, i) => arr.map((row) => row[i]));
        return temp;
      });
    },
    // 内容分析
    async getContent() {
      let tempArr = [];
      const params = {
        id: this.id,
      };
      const {
        data: { categoryHotwords, commentsHotwords },
      } = await getCampaignInsightContent(params);
      // 截取前50个词
      for (let [k, v] of Object.entries(JSON.parse(categoryHotwords))
        .sort((a, b) => b[1] - a[1])
        .slice(0, 40)) {
        tempArr.push({ name: k, value: v });
      }
      this.categoryHotwords = tempArr;
      tempArr = [];
      for (let [k, v] of Object.entries(JSON.parse(commentsHotwords))
        .sort((a, b) => b[1] - a[1])
        .slice(0, 40)) {
        tempArr.push({ name: k, value: v });
      }
      this.commentsHotwords = tempArr;
    },
    // top100
    async getArticleTop() {
      this.articleTopLoading = true;
      const params = {
        id: this.id,
        hotword: this.hotWord,
        page: this.currentPage,
        size: this.pageSize,
        platform: this.top100Platform,
        type: "category",
      };
      const {
        data: { articles, total },
      } = await getCampaignInsightArticleTop(params);
      // 只会小于等于100条数据
      // 6.1 更新：取消100条限制
      this.top100TableData = articles;
      this.totalSize = total;
      this.articleTopLoading = false;
    },
    clickHotWord(word) {
      this.tags = [];
      this.tags.push(word);
      this.hotWord = word;
      this.currentPage = 1;
      this.top100Platform = "all";
      this.getArticleTop();
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.getArticleTop();
    },
    async downloadArticle() {
      this.btnLoading = true;
      await downloadArticle(this.id);
      this.btnLoading = false;
    },
    handleTop100Platform() {
      this.currentPage = 1;
      this.getArticleTop();
    },
    tagClose() {
      this.tags = [];
      this.hotWord = "";
      this.currentPage = 1;
      this.top100Platform = "all";
      this.getArticleTop();
    },
  },
  components: {
    oneDataAnnular,
    legendCom,
    lineBar,
    staggerXBar,
    echartsWordCloud,
  },
};
</script>

<style lang="scss">
.insight_detail {
  .el-button {
    border-radius: 4px;
    min-width: 68px;
  }

  .el-button--default {
    background: #f4f3f3;
    color: #8c98a5;
  }

  .el-button--default:focus,
  .el-button--default:hover {
    color: #fff;
    border-color: #ddd;
    background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);

    .icon_hover_fill {
      fill: #fff;
    }
  }

  .el-button--mini {
    height: 28px;
    padding: 0 8px;
  }

  .el-button--primary {
    background: linear-gradient(180deg, #5181ca 0%, #3468b7 100%);
    border-radius: 5px;
  }

  .el-button--primary.is-disabled,
  .el-button--primary.is-disabled:active,
  .el-button--primary.is-disabled:focus,
  .el-button--primary.is-disabled:hover {
    color: #fff;
    background: #a0cfff;
    border-color: #a0cfff;
  }

  .el-radio-group {
    .is-checked {
      .el-radio__inner {
        background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
      }
    }

    .el-radio {
      .el-radio__input {
        .el-radio__inner {
          border-color: #e09a29;
        }
      }

      .el-radio__label {
        color: #979797;
      }
    }

    .is-active {
      .el-radio-button__inner {
        background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
        border-color: #e09a29;
        color: #fff !important;
      }
    }

    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      box-shadow: -1px 0 0 0 #e09a29;
    }

    .el-radio-button {
      .el-radio-button__inner {
        background-color: #f8f9fe;
        color: #979797;
      }
    }
  }

  .el-pagination.is-background {
    .el-pager {
      li:not(.disabled).active {
        background-color: #ebb563;
      }
    }
  }

  .dash_board_table_special_col_header {
    > div {
      border-left: 1px solid #ece9f1;
    }
  }

  .dash_board_table_special_col {
    background-color: #f2f6f9;
  }
}
</style>

<style scoped lang="scss">
.popper_class {
  word-break: break-all;
  max-width: 400px;
}

.insight_detail {
  .insight_title {
    font-size: 20px;
    margin-bottom: 18px;
    color: #4f4f4f;
  }

  .item_square_content {
    width: 26%;
    height: 156px;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 18px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .space_between_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .icon_svg {
      font-size: 34px;
    }

    .line_svg {
      width: 100%;
      margin-bottom: 4px;
    }

    .big_text {
      font-size: 18px;
      color: #404040;
    }

    .normal_text {
      font-size: 16px;
      color: #404040;
    }

    .gray_text {
      color: #aeaaaa;
    }
  }

  .item_color_content {
    width: 166px;
    height: 194px;
    padding: 14px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .icon_svg {
      font-size: 46px;
      fill: #fff;
    }

    .line_svg {
      width: 100%;
    }

    .white_text {
      color: #fff;
    }

    .big_text {
      font-size: 20px;
      margin-bottom: 4px;
    }

    .normal_text {
      font-size: 18px;
    }
  }

  .item_annular_content {
    position: relative;

    .annular_content {
      width: 170px;
      height: 170px;
    }

    .tooltip_content {
      width: 100px;
      height: 90px;
      position: absolute;
      top: 27%;
      left: 23%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .tooltip_number {
        font-size: 26px;
        color: #11263c;
        margin-bottom: 6px;
      }

      .tooltip_text {
        font-size: 24px;
        color: #929598;
        margin-right: 4px;
      }

      .tooltip_icon {
        font-size: 18px;
      }
    }
  }

  .echarts_word_cloud_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: -10px 0 10px;

    .echarts_word_cloud_title {
      height: 32px;
      line-height: 32px;
      font-size: 16px;
    }

    .echarts_word_cloud_content {
      height: 300px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      margin: 12px 20px 0;
    }
  }
}
</style>
