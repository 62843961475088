<template>
  <div className="annularPieDiv" :ref="ref"></div>
</template>

<script>
/*
 * data: number
 * */

import * as echarts from "echarts/core";
import { PieChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([PieChart, CanvasRenderer]);
import { nextTick } from "vue";

export default {
  name: "one-data-annular",
  data() {
    return {
      myChart: "",
      timer: null,
    };
  },
  props: {
    // 10%, 10, null
    data: {
      default: null,
    },
    ref: {
      type: String,
      default: "oneDataAnnular",
    },
  },
  computed: {
    series() {
      let value;
      if (typeof this.data === "string") {
        value = this.data.slice(0, -1) * 1;
      } else if (typeof this.data === "number") {
        value = this.data;
      } else {
        value = 0;
      }
      const addValue = 100 - value;
      const res = [
        {
          type: "pie",
          radius: ["72%", "84%"],
          silent: true,
          label: {
            show: false,
          },
          data: [
            {
              value,
              name: "值",
              itemStyle: {
                opacity: 0,
              },
            },
            {
              value: addValue,
              name: "互补值",
              itemStyle: {
                shadowColor: "rgba(44, 41, 41, 0.1)",
                shadowOffsetY: 10,
                shadowBlur: 20,
              },
            },
          ],
        },
        {
          type: "pie",
          radius: ["72%", "100%"],
          silent: true,
          label: {
            show: false,
          },
          data: [
            { value, name: "值" },
            { value: addValue, name: "互补值", itemStyle: { opacity: 0 } },
          ],
        },
      ];
      return res;
    },
  },
  mounted() {
    nextTick(() => {
      this.myChart = echarts.init(this.$refs[this.ref]);
      this.drawPie();
      this.resize();
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", () => {
      clearTimeout(this.timer);
      this.myChart.dispose();
    });
  },
  methods: {
    drawPie() {
      this.myChart.setOption({
        series: this.series,
        textStyle: {
          fontFamily: "Microsoft YaHei",
        },
        color: ["#2F3F9A", "#D3D9EA"],
      });
    },
    resize() {
      window.addEventListener("resize", () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.myChart.resize();
        }, 300);
      });
    },
  },
  watch: {
    data() {
      this.drawPie();
    },
  },
};
</script>

<style scoped lang="scss">
.annularPieDiv {
  width: 100%;
  height: 100%;
}
</style>
